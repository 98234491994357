.caixaFerramenta {
  max-width: 100%;
  /* height: 410px; */
  flex: 5;
  background-color: whitesmoke;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #bbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
}

.imgCaixaFerramenta {
  width: 100%;
  height: 200px;
  min-height: 200px;
  object-fit: contain;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
}
